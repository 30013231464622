import { getApolloContext } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState, useEffect, useContext } from "react";

const UPDATE_HOME_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, home_kit: $kit) {
      home_team_kit
    }
  }
`;

const UPDATE_AWAY_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, away_kit: $kit) {
      away_team_kit
    }
  }
`;

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      formation
      code
      country
      country_code
      competition_type
      badge
    }
  }
`;

const GAMES = gql`
  query Games {
    games {
      _id
      time
      description
      date
      competition {
        _id
        name
        badge
        competition_type
        colour
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      opta_team_stats
      opta_player_stats
      config
    }
  }
`;

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      stadium {
        opta_name
        name
      }
      competition {
        _id
        name
        badge
      }
      home_team {
        _id
        name
        short_name
        variables
      }
      away_team {
        _id
        name
        short_name
        variables
      }
      home_scorers
      away_scorers
      live_data
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame(
    $id: String!
    $live_data: JSON
    $lineup: JSON
    $home_scorers: JSON
    $away_scorers: JSON
    $home_penalties: JSON
    $away_penalties: JSON
  ) {
    updateGame(
      id: $id
      live_data: $live_data
      lineup: $lineup
      home_scorers: $home_scorers
      away_scorers: $away_scorers
      home_penalties: $home_penalties
      away_penalties: $away_penalties
    ) {
      live_data
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      position
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        opta_first_name
        opta_last_name
        title
        nationality {
          _id
          opta_code
          opta_name
        }
      }
      team {
        name
        short_name
      }
    }
  }
`;

const STANDINGS_BY_SEASON = gql`
  query StandingsBySeason($season: String, $date: String) {
    standingsBySeason(season: $season, date: $date) {
      _id
      date
      stage
      teams {
        team {
          name
          variables
        }
        points
        matches_drawn
        matches_lost
        matches_played
        matches_won
        goal_difference
        rank
        rank_status
      }
    }
  }
`;

const GROUPS_BY_SEASON = gql`
  query GroupsBySeason($season: String!) {
    groupsBySeason(season: $season) {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUPS = gql`
  query Groups {
    groups {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUP_BY_ID = gql`
  query Groups($id: String!) {
    groupById(id: $id) {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const APIContext = React.createContext([{}, () => {}]);

const APIProvider = (props) => {
  const { client } = useContext(getApolloContext());
  let serverAddress = window.dataUrl.replace("ws", "http");

  if (serverAddress.slice(-1) === "/") {
    serverAddress = serverAddress.slice(0, -1);
  }

  function getSeason(seasonId) {
    return fetch(serverAddress + "/seasons/" + (seasonId ? seasonId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId ? officialId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }
  function getStadium(stadiumId) {
    return fetch(serverAddress + "/stadiums/" + (stadiumId ? stadiumId : ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function updatePlayer(player) {
    return fetch(serverAddress + "/players/" + player._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(player),
    });
  }
  function updateLineup({ data, fixture, team }) {
    return fetch(serverAddress + "/games/" + fixture._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [`live_data.${team}_team_lineup`]: data },
      }),
    });
  }

  function getSquads() {
    return fetch(serverAddress + "/squads/")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getTeams({ team }) {
    return fetch(serverAddress + "/teams/" + (team || "?$orderby=name"))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAMES,
      })
      .then((response) => {
        return response.data.games.find((game) => game._id === fixtureId);
      })
      .catch((err) => console.error(err));
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }

  function updateGame({
    id,
    live_data,
    lineup,
    home_scorers,
    away_scorers,
    home_penalties,
    away_penalties,
  }) {
    if (lineup) {
      if (lineup.home) {
        lineup.home = lineup.home.map((player) => {
          return { ...player, squad: player?.squad?._id ?? player?.squad };
        });
      }
      if (lineup.away) {
        lineup.away = lineup.away.map((player) => {
          return { ...player, squad: player?.squad?._id ?? player?.squad };
        });
      }
    }
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: {
          id,
          live_data,
          lineup,
          home_scorers,
          away_scorers,
          home_penalties,
          away_penalties,
        },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }
  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getLineup({ fixtureId, team }) {
    return getFixture({ fixtureId: fixtureId }).then((fixture) => {
      let team_lineup = fixture.live_data[`${team}_team_lineup`];
      let url = "";

      return getSquadPlayers({
        teamId: fixture[team + "_team"],
        seasonId: fixture.season,
      }).then((squadPlayers) => {
        if (team_lineup && team_lineup.length > 1) {
          url =
            serverAddress +
            "/players/?$orderby=last_name&$filter=_id $in " +
            team_lineup.map((d) => d.player).join(",");
        } else if (team_lineup && team_lineup.length > 0) {
          url = serverAddress + "/players/" + team_lineup[0].player;
        }

        if (url === "") {
          return Promise.resolve();
        }

        return fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (Array.isArray(data)) {
              return team_lineup.map((l) => {
                return {
                  ...l,
                  ...squadPlayers.find((p) => p.player === l.player),
                  ...data.find((p) => p._id === l.player),
                  order: l.order,
                };
              });
            } else {
              return team_lineup.map((l) => {
                return { ...l, ...data, order: l.order };
              });
            }
          });
      });
    });
  }

  function getPlayersBySearch({ search, count, limit, page }) {
    let url =
      serverAddress + "/players?$filter=last_name $regex '^" + search + "'";
    if (count) {
      url += "&$count";
    }
    if (limit) {
      url += "&$limit=" + limit;
    }
    if (page) {
      url += "&$skip=" + page;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function createFixture({ fixture }) {
    let newFixture = {
      ...fixture,
      live_data: { home_score: 0, away_score: 0 },
    };
    return fetch(serverAddress + "/games/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newFixture),
    });
  }

  function deleteFixture({ fixtureId }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "DELETE",
    });
  }

  function getOfficial(officialId) {
    return fetch(serverAddress + "/officials/" + (officialId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateGameOfficial({ fixtureId, official, type }) {
    return fetch(serverAddress + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [type]: official._id },
      }),
    });
  }

  function updateHeadCoach({ teamId, coach }) {
    return fetch(serverAddress + "/teams/" + teamId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { coach: coach },
      }),
    });
  }
  function getPlayer({ playerId }) {
    return fetch(serverAddress + "/players/" + (playerId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updatePlayer(player) {
    if (player._id) {
      player = { ...player, nationality: player.nationality._id };
      return fetch(serverAddress + "/players/" + player._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    } else {
      return fetch(serverAddress + "/players/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(player),
      });
    }
  }

  function getGroupsBySeason({ seasonId }) {
    if (seasonId) {
      return client
        .query({
          query: GROUPS_BY_SEASON,
          fetchPolicy: "network-only",
          variables: {
            season: seasonId,
          },
        })
        .then((response) => {
          return response.data.groupsBySeason;
        })
        .catch((err) => console.error(err));
    }
  }

  function getGroup(groupId) {
    if (groupId) {
      return client
        .query({
          query: GROUP_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: groupId,
          },
        })
        .then((response) => {
          return response.data.groupById;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .query({
          query: GROUPS,
        })
        .then((response) => {
          return response.data.groups;
        })
        .catch((err) => console.error(err));
    }
  }

  function updateKit({ id, kit, team }) {
    return client
      .mutate({
        mutation: team === "home" ? UPDATE_HOME_KIT : UPDATE_AWAY_KIT,
        variables: { id, kit },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getStandings({ season, date }) {
    return client
      .query({
        query: STANDINGS_BY_SEASON,
        fetchPolicy: "network-only",
        variables: {
          season,
          date,
        },
      })
      .then((response) => {
        return response.data.standingsBySeason;
      })
      .catch((err) => console.error(err));
  }

  return (
    <APIContext.Provider
      value={{
        updatePlayer,
        updateLineup,
        getLineup,
        getFixture,
        getTeams,
        getSquad,
        getPlayersBySearch,
        getSquads,
        createFixture,
        deleteFixture,
        getOfficial,
        updateGameOfficial,
        updateHeadCoach,
        getCompetition,
        getSeason,
        getStadium,
        getOfficial,
        getPlayer,
        getFixtures,
        updatePlayer,
        updateGame,
        getGroupsBySeason,
        getGroup,
        updateKit,
        getStandings,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};
export { APIContext, APIProvider };
